// Modules

@use 'vars'

// Mixins

// mobile first
=breakpoint($br, $media-feature: 'min-width')
  @media screen and (#{$media-feature}: $br)
    @content
