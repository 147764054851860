// Modules

// plugins
@use '../plugins/plugins-dir' as plugins

// media lib
@use '../base/media-lib'

/* LOGOS

.logos__container
  grid-template-columns: repeat(3, max-content)
  justify-content: center
  align-items: center
  gap: 4rem
  +plugins.padding(null null 2rem)

  // small devices
  +media-lib.breakpoint($br: media-lib.$xsm, $media-feature: 'max-width')
    gap: 2.5rem

  // medium devices
  +media-lib.breakpoint($br: media-lib.$md)
    gap: 4rem 8rem

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    grid-template-columns: repeat(6, max-content)

.logos__img
  width: 40px
  transition: .3s
  opacity: .4

  &:hover
    opacity: 1

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    width: 50px
