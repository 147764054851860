// Modules

// plugins
@use '../plugins/plugins-dir' as plugins

// placeholders
@use '../base/placeholders'

// media lib
@use '../base/media-lib'

/* FEATURED

.featured__container
  +plugins.padding(1rem null null)

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    +plugins.padding(1rem null 2.5rem)

.featured__filters
  display: flex
  justify-content: center
  align-items: center
  column-gap: 1rem
  +plugins.margin(null null 3.5rem)

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    column-gap: 2rem
    +plugins.margin(null null 4.5rem)

.featured__item
  +plugins.size(48px)
  +plugins.padding(.75rem)
  border: none
  border-radius: .75rem
  background-color: var(--container-color)
  outline: none
  font-size: var(--normal-font-size)
  color: var(--white-color)
  cursor: pointer
  transition: .3s

  &:hover
    background-color: var(--first-color)

    span, img
      opacity: 1

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    +plugins.size(52px)

.featured__item img
  width: 1.5rem

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    width: 2rem

.featured__item span,
.featured__item img
  opacity: .3
  transition: .3s

.featured__content
  grid-template-columns: 228px
  justify-content: center
  row-gap: 2.5rem

  // small devices
  +media-lib.breakpoint($br: media-lib.$sm)
    grid-template-columns: repeat(2, 228px)

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    grid-template-columns: repeat(3, 248px)
    gap: 4rem

.featured__card
  +plugins.padding(2rem 1.5rem 1.5rem)
  border-radius: 1rem
  background-color: var(--container-color)
  position: relative

  &:hover
    .featured__img
      transform: translateX(-.25rem)

.featured__card .shape__smaller
  +plugins.margin(auto)
  position: absolute
  inset: 0

.featured__title,
.featured__subtitle,
.featured__img
  position: relative

.featured__title
  @extend %card-title

.featured__subtitle
  @extend %card-subtitle

.featured__img
  width: 180px
  +plugins.margin(1.5rem 0)
  transition: .3s

.featured__price
  @extend %card-price

.featured__button
  +plugins.padding(.75rem 1rem)
  border-radius: 1rem 0 1rem

  @extend %card-button
