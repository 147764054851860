// Modules

// plugins
@use '../plugins/plugins-dir' as plugins

// media lib
@use '../base/media-lib'

/* OFFER

.offer
  position: relative

.offer__container
  grid-template-rows: max-content 224px

  // small devices
  +media-lib.breakpoint($br: media-lib.$xsm, $media-feature: 'max-width')
    grid-template-rows: max-content 180px

  // small devices
  +media-lib.breakpoint($br: media-lib.$sm)
    grid-template-columns: .5fr
    grid-template-rows: initial
    justify-content: center

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    grid-template-columns: repeat(2, 1fr)
    align-items: center
    column-gap: 5rem
    +plugins.padding(null null 2rem)

.offer__bg
  +plugins.size(100%)
  object-fit: cover
  object-position: center
  +plugins.position(absolute, 0 null null 0)

.offer__data,
.offer__img
  position: relative

.offer__data
  text-align: center

  // small devices
  +media-lib.breakpoint($br: media-lib.$sm)
    +plugins.margin(null null 4rem)

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    text-align: initial
    +plugins.margin(null null 0)

.offer__title
  +plugins.margin(null null 2rem)

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    text-align: initial

.offer__description
  +plugins.margin(null null 3rem)

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    +plugins.padding(null 6rem null null)

.offer__img
  max-width: initial
  width: 400px
  +plugins.position(absolute, null -5.5rem 2rem null)

  // small devices
  +media-lib.breakpoint($br: media-lib.$xsm, $media-feature: 'max-width')
    width: 340px

  // medium devices
  +media-lib.breakpoint($br: media-lib.$sm)
    +plugins.position(relative, null initial initial null)

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    width: 450px
