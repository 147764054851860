/* ANIMATIONS

/* button animation
@keyframes button
  $green: hsl(158, 98%, 43%)

  0%
    box-shadow: 0 0 12px $green
  50%
    box-shadow: 0 0 24px $green
