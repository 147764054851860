// Modules

// variables
@use 'vars'

// plugins
@use '../plugins/plugins-dir' as plugins

/* TYPOGRAPHY

+plugins.font-face('Exo', '#{vars.$fonts-dir}/Exo-Regular', ('woff2', 'woff'))
  font:
    style: normal
    weight: 400

+plugins.font-face('Exo', '#{vars.$fonts-dir}/Exo-Medium', ('woff2', 'woff'))
  font:
    style: normal
    weight: 500

+plugins.font-face('Exo', '#{vars.$fonts-dir}/Exo-SemiBold', ('woff2', 'woff'))
  font:
    style: normal
    weight: 600
