// Modules

@use 'media-lib'

/* ROOT STYLES

:root
  // dimensions
  --header-height: 3.5rem

  // colors
  --first-color: hsl(219, 69%, 56%)
  --first-color-alt: hsl(219, 69%, 52%)
  --title-color: hsl(219, 8%, 95%)
  --text-color: hsl(219, 8%, 75%)
  --text-color-light: hsl(219, 4%, 55%)
  --white-color: hsl(0, 0%, 100%)
  --body-color: hsl(219, 4%, 4%)
  --container-color: hsl(219, 4%, 7%)

  // fonts
  
  --body-font: 'Exo', sans-serif
  --body-font: 'Kanit', sans-serif
  --h1-font-size: 1.5rem
  --h2-font-size: 1.25rem
  --h3-font-size: 1rem
  --normal-font-size: .938rem
  --small-font-size: .813rem
  --smaller-font-size: .75rem

  --font-medium: 500
  --font-semi-bold: 600

  // z-index
  --z-tooltip: 10
  --z-fixed: 100

  // responsive typography
  +media-lib.breakpoint($br: media-lib.$lg)
    --h1-font-size: 2.25rem
    --h2-font-size: 1.5rem
    --h3-font-size: 1.25rem
    --normal-font-size: 1rem
    --small-font-size: .875rem
    --smaller-font-size: .813rem
