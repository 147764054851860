// Modules

// plugins
@use '../plugins/plugins-dir' as plugins

// media lib 
@use '../base/media-lib'

/* NAV

.nav
  display: flex
  justify-content: space-between
  align-items: center
  height: var(--header-height)

  // medium devices
  +media-lib.breakpoint($br: media-lib.$md)
    height: calc(var(--header-height) + 1.5rem)

.nav__logo,
.nav__toggle
  display: inline-flex
  color: var(--title-color)

.nav__logo
  align-items: center
  column-gap: .25rem
  font-weight: var(--font-medium)
  transition: .3s

  &:hover
    color: var(--first-color)

.nav__logo i
  font-size: 1.25rem

.nav__toggle,
.nav__close
  // medium devices
  +media-lib.breakpoint($br: media-lib.$md)
    display: none

.nav__toggle
  font-size: 1.25rem
  cursor: pointer

.nav__menu
  +media-lib.breakpoint($br: media-lib.$md, $media-feature: 'max-width')
    +plugins.size(100%)
    +plugins.position(fixed, 0 -100% null null)
    transition: .3s
    overflow: hidden

    @supports (backdrop-filter: blur())
      background-color: hsla(0, 0%, 100%, .1)
      +plugins.prefixer(backdrop-filter, blur(35px), ('webkit'))

    @supports not (backdrop-filter: blur())
      background-color: var(--body-color)

.nav__list
  display: flex
  flex-direction: column
  row-gap: 3rem
  +plugins.padding(9rem null null)
  text-align: center

  // medium devices
  +media-lib.breakpoint($br: media-lib.$md)
    flex-direction: row
    column-gap: 3rem
    +plugins.padding(0 null null)

.nav__link
  text-transform: uppercase
  font:
    size: var(--h2-font-size)
    weight: var(--font-medium) 
  color: var(--title-color)
  transition: .3s

  &:hover
    color: var(--first-color)

  // medium devices
  +media-lib.breakpoint($br: media-lib.$md)
    font-size: var(--normal-font-size)
    text-transform: initial

.nav__close
  font-size: 2rem
  color: var(--white-color)
  +plugins.position(absolute, 1rem 1rem null null)
  cursor: pointer
