// Modules

@use '../plugins/plugins-dir' as plugins

/* MICRO CLASSES

.active-link
  color: var(--first-color)

// change background header
.scroll-header
  +plugins.border-bottom-radius(1rem)
  background-color: var(--body-color)
  box-shadow: 0 2px 4px hsla(0, 0%, 1%, 1)

.show-menu
  right: 0

.hide-svg
  +plugins.size(0)
  +plugins.position(absolute, 0 0 null null)

.blurred-content
  @supports not (backdrop-filter: blur())
    +plugins.position(absolute, 0 0 null)
    filter: url(#blur-effect)
    z-index: -1
    opacity: .5

.show-scroll
  bottom: 3rem
