// Modules

// plugins
@use '../plugins/plugins-dir' as plugins

// media lib
@use '../base/media-lib'

/* FOOTER

.footer
  position: relative
  overflow: hidden

.footer 
  .shape__big,
  .shape__small
    position: absolute

  .shape__big
    +plugins.size(300px)
    top: 6rem
    left: -12rem

  .shape__small
    right: -13rem
    bottom: -6rem

.footer__container
  row-gap: 2.5rem
  position: relative

  // medium devices
  +media-lib.breakpoint($br: media-lib.$md)
    grid-template-columns: repeat(2, 1fr)

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    grid-template-columns: repeat(4, max-content)
    justify-content: space-between

.footer__logo
  display: inline-flex
  align-items: center
  column-gap: .25rem
  +plugins.margin(null null 1.25rem)
  font:
    size: var(--h2-font-size)
    weight: var(--font-semi-bold)
  color: var(--title-color)
  transition: .3s

  &:hover
    color: var(--first-color)

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    column-gap: .5rem

.footer__logo i
  font:
    size: 1.5rem
    weight: 500

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    font-size: 2rem

.footer__title
  +plugins.margin(null null 1.25rem)
  font-size: var(--h3-font-size)

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    +plugins.margin(null null 1.5rem)

.footer__links
  display: flex
  flex-direction: column
  row-gap: .5rem

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    row-gap: .75rem

.footer__link,
.footer__social-link
  color: var(--text-color)
  transition: .3s

  &:hover
    color: var(--title-color)

.footer__social
  display: flex
  column-gap: 1.5rem

.footer__social-link
  font-size: 1.25rem

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    font-size: 1.5rem

.footer__copy
  display: block
  +plugins.margin(4.5rem null null)
  font-size: var(--smaller-font-size)
  color: var(--text-color-light)
  text-align: center

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    +plugins.margin(8rem null null)
    +plugins.padding(null null 1rem)
