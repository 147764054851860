// Modules

@use '../plugins/plugins-dir' as plugins

/* BASE STYLES

*, 
*:before, 
*:after
  box-sizing: border-box
  +plugins.padding(0)
  +plugins.margin(0)

html 
  scroll-behavior: smooth

body
  background-color: var(--body-color)
  font:
    family: var(--body-font)
    size: var(--normal-font-size)
  color: var(--text-color)

h1, h2, h3
  font-size: var(--font-semi-bold)
  color: var(--title-color)

h1
  +plugins.margin(0)

ul
  list-style: none

a
  text-decoration: none

img
  max-width: 100%
  height: auto
