// Modules

@use '../plugins/plugins-dir' as plugins

/* HEADER

.header
  width: 100%
  background-color: transparent
  +plugins.position(fixed, 0 null null 0)
  z-index: var(--z-fixed)
