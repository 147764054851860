// Modules

// root styles
@use 'base/root'

// theme
@use 'theme/theme-dir'

// partials
@use 'base/typography'
@use 'base/animations'
@use 'base/base'
@use 'layouts/layouts-dir'
@use 'modules/modules-dir'

// normalizer
@import ~normalize.css
