// Modules

// plugins
@use '../plugins/plugins-dir' as plugins

// placeholders
@use '../base/placeholders'

// media lib
@use '../base/media-lib'

/* FEATURES

.features
  position: relative
  overflow: hidden

.features__container
  grid-template-columns: 285px
  justify-content: center
  +plugins.padding(2rem null null)

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    +plugins.padding(2rem null 3rem)

.features__group
  display: grid
  position: relative
  z-index: 10

.features__img
  justify-self: center
  width: 150px

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    width: 200px

.features__card
  width: 114px
  +plugins.padding(.75rem 2rem)
  border-radius: 1.25rem
  text-align: center
  color: var(--title-color)
  position: absolute

  @extend %bg-blur

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    width: 134px
    +plugins.padding(.75rem 2.5rem)

.features__card-1
  top: 4rem
  left: 1.5rem

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    left: -1rem

.features__card-2
  top: 8rem
  right: 1rem

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    right: -2.5rem

.features__card-3
  left: 1.5rem
  bottom: 2rem

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    left: -1rem
    bottom: 7rem

.features__card-title
  +plugins.margin(null null .25rem)
  font-size: var(--h3-font-size)

.features__card-description
  font-size: var(--smaller-font-size)

.features__map
  max-width: initial
  width: 450px
  +plugins.position(absolute, 8rem null null -3rem)
  transform: rotate(-22deg)

  // small devices
  +media-lib.breakpoint($br: media-lib.$sm)
    left: 50%
    transform: translateX(-50%) rotate(-22deg)

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    width: 650px
    top: 11rem
