/* SCROLLBAR

::-webkit-scrollbar
  width: .6rem
  background-color: hsl(219, 4%, 16%)
  border-radius: 1rem

::-webkit-scrollbar-thumb
  background-color: hsl(219, 4%, 24%)
  border-radius: 1rem

  &:hover
    background-color: hsl(219, 4%, 32%)
