// Modules

// plugins
@use '../plugins/plugins-dir' as plugins

// placeholders
@use '../base/placeholders'

// media lib
@use '../base/media-lib'

/* POPULAR

.popular__container
  +plugins.padding(1rem null null)

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    width: 875px
    +plugins.padding(3rem null null)

.popular__card
  width: 238px
  +plugins.margin(null null 3.5rem)
  +plugins.padding(2rem 1.5rem 1.5rem)
  background-color: var(--container-color)
  border-radius: 1rem
  position: relative
  overflow: hidden

  &:hover
    .popular__img
      transform: translateY(-.25rem)

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    width: 258px
    +plugins.margin(null null 5rem)
    border-radius: 1.25rem

.popular__card .shape__smaller
  +plugins.position(absolute, -2.5rem null null -2.5rem)

.popular__title,
.popular__subtitle,
.popular__img
  position: relative

.popular__title
  @extend %card-title

.popular__subtitle
  @extend %card-subtitle

.popular__img
  width: 160px
  +plugins.margin(.75rem null 1.25rem 1.5rem)
  transition: .3s

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    width: 180px
    +plugins.margin(.75rem null 1.25rem 2rem)

.popular__data
  display: grid
  grid-template-columns: repeat(2, max-content)
  gap: .5rem 1.25rem
  +plugins.margin(null null 2rem)

.popular__data-group
  display: inline-flex
  align-items: center
  column-gap: .5rem
  font-size: var(--small-font-size)
  color: var(--white-color)

.popular__data i
  font-size: 1rem

.popular__price
  @extend %card-price

.popular__button
  +plugins.padding(.75rem 1rem)
  border-radius: 1rem 0 1rem

  @extend %card-button
