// Modules

// plugins
@use '../../plugins/plugins-dir' as plugins

// media lib
@use '../../base/media-lib'

/* SHAPE

.shape
  border-radius: 50%
  background-color: hsla(219, 33%, 32%, .5)
  filter: blur(112px)

.shape__big
  +plugins.size(400px)

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    +plugins.size(500px)

.shape__small
  +plugins.size(300px)

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    +plugins.size(400px)

.shape__smaller
  +plugins.size(180px)
  filter: blur(64px)
