// Modules

// plugins
@use '../plugins/plugins-dir' as plugins

// placeholders
@use '../base/placeholders'

// media lib
@use '../base/media-lib'

/* ABOUT

.about__container
  row-gap: 6rem

  // medium devices
  +media-lib.breakpoint($br: media-lib.$md)
    grid-template-columns: repeat(2, 1fr)

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    align-items: center
    column-gap: 6rem

.about__group
  position: relative

  // small devices
  +media-lib.breakpoint($br: media-lib.$sm)
    width: 350px
    justify-self: center

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    width: 480px

.about__img
  width: 310px
  border-radius: .5rem

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    width: 480px

.about__card
  width: 180px
  +plugins.padding(1rem .75rem)
  border-radius: 1.25rem
  text-align: center
  +plugins.position(absolute, null 0 -2.5rem null)

  @extend %bg-blur

  // small devices
  +media-lib.breakpoint($br: media-lib.$xsm, $media-feature: 'max-width')
    width: 150px

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    width: 198px
    +plugins.padding(1rem 1.25rem)

.about__card-title
  +plugins.margin(null null .5rem)
  font-size: var(--h3-font-size)
  color: black

.about__card-description
  font-size: var(--smaller-font-size)
  color: orange

.about__title
  +plugins.margin(null null 2rem)
  text-align: initial

.about__description
  +plugins.margin(null null 2rem)

  // large devices
  +media-lib.breakpoint($br: media-lib.$xl)
    +plugins.margin(null null 3rem)
    +plugins.padding(null 4rem null null)
